import React from 'react'
import { Layout } from '../../components/AppLayout'
import { AppRecessionproofyourESourcingstrategyBanner } from '../../components/AppRecessionproofyourESourcingstrategyBanner'
import { RDHelmet } from '../../components/RDHelmet'
import { AppRecessionproofyourESourcingstrategyWithNav } from '../../components/AppRecessionproofyourESourcingstrategyWithNav'

const RecessionproofyourESourcingstrategy = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'Recession-proof your eSourcing strategy'}
        contentDescription="Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
        contentKeywords="content='Business spend management software, SaaS spend management software, Enterprise spend management software, Spend management software market, Spend management software companies, Business spend management platform, Artificial intelligence software, AI-based spend management solution'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
        contentOgUrl="https://raindrop.com/resources/RecessionproofyourESourcingstrategy"
        schemaMarkup={schemaMarkup}
      />
      <AppRecessionproofyourESourcingstrategyBanner />
      <AppRecessionproofyourESourcingstrategyWithNav />
    </Layout>
  )
}

export default RecessionproofyourESourcingstrategy
